.ci::after {
  content: " " !important;
}
.ui-input__label {
  padding-right: 0px !important;
  margin-bottom: 0px !important;
}
.bf {
  width: 100%;
}
