input:checked + label > img {
  opacity: 1;
  border: 2px solid #1c8dab;
  border-radius: 4px;
}
input + label > img {
  cursor: pointer;
  border: 2px solid #b3b1ae;
  opacity: 0.3;
  border-radius: 4px;
}
label > input + img {
  /* IMAGE STYLES */
  cursor: pointer;
  border: 2px solid #b3b1ae;
}
input + label > .responsive-rectangle {
  cursor: pointer;
  border: 2px solid #b3b1ae;

  border-radius: 4px;
}

.responsive-rectangle {
  background: transparent;
  float: left;
  margin: 0 5px;
  max-width: 119px;
  text-align: center;
  width: 100%;
}
input:checked + label > .responsive-rectangle {
  opacity: 1;
  border: 2px solid #1c8dab;
  border-radius: 4px;
}
.image-wrapper {
  padding-top: 62%;
  position: relative;
  width: 100%;
}
.image-wrapper img {
  cursor: pointer;
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  right: 0;
  position: absolute;
  top: 0;
}
