.backgroundColorBlue {
  background-color: #208ead !important;
}

.colorWhite {
  color: white !important;
}

.colorGreen {
  color: #b6cf56;
}

.colorBlue {
  color: #208ead !important;
}

body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}

.flex4 {
  flex: 4;
}

.hidden {
  display: none;
}

.ui-dropdown__container {
  width: 100% !important;
}

.numberAngle {
  background: linear-gradient(#0f2741 0, #2f425a 100%);
  margin: 0 auto 0 85px;
  position: relative;
  transform: skew(-15deg);
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0px 0;
  border-color: #208ead transparent transparent transparent;
  left: 0;
  top: 0;
  position: absolute;
}

.contentTriangle {
  color: white;
  margin-top: -30px;
  margin-left: 5px;
}

.modalWindow {
  /* height: 100%;
  max-height: 250px; */
}
@media only screen and (min-width: 768px) {
  .width80 {
    width: 80% !important;
  }
}

@media only screen and (max-device-width: 480px) {
  .mobileMarginTop0 {
    margin-top: 0px !important;
  }

  .mobileAutoWidth {
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    /* width: 80% !important; */
  }

  .mobileOverflowY {
    height: 100vh;
    overflow-y: scroll;
  }

  .mobileheightmax430 {
    height: 100vh !important;
  }

  .mobileOverflowXHidden {
    overflow-x: hidden !important;
  }

  .mobilePaffing0 {
    padding: 0px !important;
  }

  .mobileWidth80 {
    width: 80% !important;
  }

  .mobileOverflowHidden {
    overflow: hidden !important;
  }

  .mobileMarginLeft0 {
    margin-left: 0px !important;
  }
}
